import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { DateTime } from 'luxon';

import { RichText } from 'prismic-reactjs';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import Image from '../components/Image';

import { breakpoint } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(30)} auto ${rem(0)};
  padding-bottom: ${rem(80)};
`;

const Inner = styled.div`
  display: block;
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.grey};

  ${breakpoint('mediumlarge')} {
    display: flex;
  }
`;

const ImageCol = styled.div`
  display: block;
  /* padding: ${(props) => props.theme.spacing.gutter}; */
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.black};

  ${breakpoint('mediumlarge')} {
    width: 40%;
  }
`;

const Thumbnail = styled(Image)`
  display: block;
  width: 100%;
`;

const Content = styled.div`
  display: block;
  padding: 1.7rem ${(props) => props.theme.spacing.gutter};
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 60%;
  }
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(26)};

  ${breakpoint('large')} {
    font-size: ${rem(32)};
  }
`;

const Tags = styled.h4`
  display: block;
  width: 100%;
  margin: 0 0 ${rem(10)} 0;
  font-size: ${rem(17)};

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }
`;

const Facts = styled.div`
  margin-top: ${rem(16)};
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.colors.greyDark};
`;

const TableCell = styled.div`
  display: block;
  padding: ${rem(7)} 0;
`;

const Label = styled.h6`
  margin: 0 ${rem(8)} 0 0;
  font-size: ${rem(15)};

  ${breakpoint('large')} {
    font-size: ${rem(16)};
  }
`;

function Client({ data }) {
  const { client } = data.prismic;

  if (!client) {
    return null;
  }

  const facts = {
    position: {
      label: 'Position',
      value: client.position,
    },
    foot: {
      label: 'Foot',
      value: client.foot,
    },
    date_of_birth: {
      label: 'Age',
      // value: client.date_of_birth,
      value: DateTime.fromISO(client.dob)
        .diffNow(['years', 'days'])
        .negate().years,
    },
    club: {
      label: 'Current club',
      value: client.club,
    },
    contract_ends: {
      label: 'Contract ends',
      value: DateTime.fromISO(client.contract_end).toFormat('dd LLL, yyyy'),
    },
  };

  return (
    <Layout>
      <SEO
        title={RichText.asText(client.seo_meta_title || client.title)}
        description={client.seo_description}
      />

      <Hero heading={RichText.asText(client.title)}>
        <p>{client.category}</p>
      </Hero>

      <Container>
        <Row>
          <Col>
            <Inner>
              <ImageCol>
                {client.client_image && (
                  <Thumbnail
                    fluid={client.client_imageSharp?.childImageSharp?.fluid}
                    fallback={client.client_image}
                    alt={RichText.asText(client.title)}
                  />
                )}
              </ImageCol>
              <Content>
                <Title>{RichText.asText(client.title)}</Title>
                <Tags>{client.category}</Tags>

                {client.content && <RichText render={client.content} />}

                <Facts>
                  {Object.keys(facts).map((item) => (
                    <TableRow key={item}>
                      <TableCell>
                        <Label>{facts[item].label}</Label>
                      </TableCell>
                      <TableCell>{facts[item].value}</TableCell>
                    </TableRow>
                  ))}
                </Facts>
              </Content>
            </Inner>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

Client.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query($uid: String!) {
    prismic {
      client(lang: "en-gb", uid: $uid) {
        client_image
        client_imageSharp {
          childImageSharp {
            fluid(maxWidth: 900, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        club
        content
        dob
        contract_end
        foot
        position
        hero_text
        seo_description
        seo_meta_title
        title
        category
      }
    }
  }
`;

export default Client;
